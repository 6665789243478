export const displayDollarValue = (
  value: bigint,
  hideDollarSign: boolean = false
): string => {
  const stringValue = value.toString();
  const length = stringValue.length;

  if (length <= 3) {
    return hideDollarSign ? stringValue : "$" + stringValue;
  }

  let result = "";
  let count = 0;

  for (let i = length - 1; i >= 0; i--) {
    result = stringValue[i] + result;
    count++;

    if (count === 3 && i !== 0) {
      result = "," + result;
      count = 0;
    }
  }

  return hideDollarSign ? result : "$" + result;
};
