import { displayDollarValue } from "utils";
import { truncate } from "./truncate";

export function formatCurrencyAmount(
  amount: bigint,
  decimals: number = 18,
  decimalsToShow: number = 4
): string {
  const stringAmount = amount.toString();

  let stringParsed: string;

  if (stringAmount.length <= decimals) {
    stringParsed = `0.${stringAmount.padStart(decimals, "0")}`;
  } else {
    const integerPart = displayDollarValue(BigInt(stringAmount.slice(0, stringAmount.length - decimals)), true);

    const decimalPart = stringAmount.slice(stringAmount.length - decimals);

    stringParsed = `${integerPart}.${decimalPart}`;
  }

  return truncate(stringParsed, decimalsToShow);
}
