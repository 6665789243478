export type ValueWithWeight = {
  value: number;
  weight: number;
};

export const calculateWeightedAverage = (
  valuesWithWeight: ValueWithWeight[]
): number => {
  const { totalValuesWeighted, totalWeight } = valuesWithWeight.reduce(
    (acc, { value, weight }) => {
      return {
        totalValuesWeighted: acc.totalValuesWeighted + value * weight,
        totalWeight: acc.totalWeight + weight,
      };
    },
    { totalValuesWeighted: 0, totalWeight: 0 }
  );

  if (valuesWithWeight.length === 0 || totalWeight === 0) {
    return 0;
  }

  return totalValuesWeighted / totalWeight;
};
