import { Address } from "@unlockdfinance/verislabs-web3";

export const validateAddress = (address?: string): address is Address => {
  if (address === undefined) {
    throw new Error("No address provided");
  } else if (address.length !== 42) {
    throw new Error("Invalid address length");
  } else if (!address.startsWith("0x")) {
    throw new Error("Invalid address format. Address should start with 0x");
  } else if (!/^0x[0-9a-fA-F]+$/.test(address)) {
    throw new Error(
      "Invalid address format. Address should include only hexadecimal characters"
    );
  }

  return true;
};
