export const toDaysAndHoursAgo = (date: Date): string => {
  const currentDate = new Date();
  const timeDifference = currentDate.getTime() - date.getTime();

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  let resultString = "";

  if (daysDifference > 0) {
    resultString += `${daysDifference} day${daysDifference > 1 ? "s" : ""}`;
  }

  if (hoursDifference > 0) {
    if (resultString.length > 0) {
      resultString += " and ";
    }
    resultString += `${hoursDifference} hour${hoursDifference > 1 ? "s" : ""}`;
  }

  if (resultString.length === 0) {
    resultString = "Just now";
  } else {
    resultString += " ago";
  }

  return resultString;
};
