export const formatStringList = (list: string[]): string => {
  if (list.length === 0) {
    return "";
  } else if (list.length === 1) {
    return list[0];
  } else {
    const elementsComa = list.slice(0, -1).join(", ");

    const result = `${elementsComa} and ${list[list.length - 1]}`;

    return result;
  }
};
