export const parseMsToRender = (ms: number) => {
  const days = Math.floor(ms / 86400000);

  const hours = Math.floor((ms % 86400000) / 3600000);

  const minutes = Math.floor(((ms % 86400000) % 3600000) / 60000);

  const seconds = Math.floor((((ms % 86400000) % 3600000) % 60000) / 1000);

  const daysToRender = days < 10 ? `0${days}` : days;
  const hoursToRender = hours < 10 ? `0${hours}` : hours;
  const minutesToRender = minutes < 10 ? `0${minutes}` : minutes;
  const secondsToRender = seconds < 10 ? `0${seconds}` : seconds;

  return `${daysToRender}:${hoursToRender}:${minutesToRender}:${secondsToRender}`;
};
