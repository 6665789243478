export const isSomeInRange = (array: number[], ranges: number[][]): boolean => {
  for (let number of array) {
    for (let interval of ranges) {
      if (number >= interval[0] && number <= interval[1]) {
        return true;
      }
    }
  }
  return false;
};

//
export function toMin2Digits(num: number) {
  return `${num < 10 ? "0" : ""}${num}`;
}

export function toFixed(value: number) {
  const sValue = value.toString();

  if (sValue.includes("e-")) {
    const [numbers, pow] = sValue.split("e-");

    const decimals = numbers.replace(".", "");

    return `0.${"0".repeat(parseInt(pow) - 1)}${decimals}`;
  } else if (sValue.includes("e+")) {
    const [numbers, pow] = sValue.split("e+");

    const decimals = numbers.replace(".", "");

    return `${decimals}${"0".repeat(parseInt(pow) - decimals.length + 1)}`;
  } else return sValue;
}
