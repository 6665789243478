export function truncate(sn: string, decimals: number) {
  const index = sn.indexOf(".");

  if (index < 0) return sn;

  if (decimals === 0) {
    return sn.slice(0, index);
  }

  return sn.slice(0, index + decimals + 1);
}
