import { IPortableText } from "../components/RichText";

const stringToPortableText = (string: string): IPortableText => {
  return [
    {
      _key: "block-0",
      _type: "block",
      children: [
        {
          _key: "child-0",
          _type: "span",
          text: string,
        },
      ],
    },
  ];
};

export default stringToPortableText;
