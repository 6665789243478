type ParseDateToRender = (arg: Date) => string;

export const parseDateToRender: ParseDateToRender = (date) => {
  const differenceTime = Date.now() - date.getTime();

  if (differenceTime / 1000 / 60 / 60 >= 48)
    return `${Math.floor(differenceTime / 1000 / 60 / 60 / 24)} days ago`;
  if (differenceTime / 1000 / 60 / 60 > 24) return "1 day ago";
  if (differenceTime / 1000 / 60 / 60 >= 1)
    return `${Math.floor(differenceTime / 1000 / 60 / 60)}h ago`;
  if (differenceTime / 1000 / 60 >= 2)
    return `${Math.floor(differenceTime / 1000 / 60)} mins ago`;
  if (Math.floor(differenceTime / 1000 / 60) === 1) return "1 min ago";
  if (differenceTime / 1000 > 1)
    return `${Math.floor(differenceTime / 1000)} secs ago`;
  return "1 seg ago";
};
