import { Address } from "viem";

export const RAY = "1e27";
export const SECONDS_PER_YEAR = "31536000";
export const UINT_256_MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";
export const WAD = 1e18;
export const PEP = 1e10;
export const PERCENTAGE_FACTOR = 1e4;
export const HALF_PERCENT = PERCENTAGE_FACTOR / 2;
export const ADDRESS_ZERO = `0x${"0".repeat(40)}` as Address;
