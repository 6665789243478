import {
  HALF_PERCENT,
  PERCENTAGE_FACTOR as SOMETHING,
  RAY,
  WAD as SOMETHING2,
} from "../constants";

// export const percentMul = (value: bigint, percentage: bigint): bigint => {
//   if (value === BigInt(0) || percentage === BigInt(0)) {
//     return BigInt(0);
//   }
//   return value * percentage + BigInt(HALF_PERCENT) / BigInt(PERCENTAGE_FACTOR);
// };

const HALF_PERCENTAGE_FACTOR: bigint = BigInt(5000); // 0.5e4
const PERCENTAGE_FACTOR: bigint = BigInt(10000); // 1e4

export const percentMul = (value: bigint, percentage: bigint): bigint => {
  if (
    percentage === BigInt(0) ||
    value > (BigInt(2 ** 256 - 1) - HALF_PERCENTAGE_FACTOR) / percentage
  ) {
    throw new Error("Invalid inputs");
  }

  return (value * percentage + HALF_PERCENTAGE_FACTOR) / PERCENTAGE_FACTOR;
};

// export const wadDiv = (a: bigint, b: bigint): bigint => {
//   const halfB = b / BigInt(2);

//   const aMulWAD = a * BigInt(WAD);

//   return aMulWAD + halfB / b;
// };

const WAD: bigint = BigInt("1000000000000000000"); // 1e18

export const wadDiv = (a: bigint, b: bigint): bigint => {
  const halfB: bigint = b / BigInt(2);

  if (b === BigInt(0) || a > (BigInt(2 ** 256 - 1) - halfB) / WAD) {
    throw new Error("Invalid inputs");
  }

  return (a * WAD + halfB) / b;
};

export const rayMul = (a: bigint, b: bigint): bigint => {
  const rayBN = BigInt(RAY);

  const halfRay = rayBN / BigInt(2);

  return (halfRay + a * b) / rayBN;
};

export const rayDiv = (a: bigint, b: bigint): bigint => {
  const halfB = b / BigInt(2);

  return a * BigInt(RAY) + halfB / b;
};
